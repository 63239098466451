import { Roboto } from 'next/font/google'
import { createTheme } from '@mui/material/styles'
import { backdropClasses, colors } from '@mui/material'

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
})

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3A602A',
      light: '#5C8C48',
      dark: '#28431D'
    },
    secondary: {
      main: '#481310',
      light: '#F6F3F3',
      dark: '#2B0A08'
    },
    divider: '#CECCCC',
    action: {
      active: '#3A602A',
      disabled: '#fff',
      disabledBackground: '#707070'
    },
    text: { primary: '#4A4A4A' }
  },

  typography: {
    fontFamily: roboto.style.fontFamily,
    fontSize: 16,
    fontWeightBold: 600,
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.25,
      color: '#3A602A'
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: 1.172,
      color: '#481310'
    },
    h3: {
      fontSize: '1.375rem',
      fontWeight: 500,
      lineHeight: 1.172,
      color: '#481310'
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.35,
      color: '#481310'
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#4A4A4A'
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5
    },
    bodyEmphasised: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5
    },
    linkText: {
      color: '#345828',
      textDecoration: 'underline',
      textUnderlineOffset: '0.375rem',
      fontWeight: 500,
      lineHeight: 1.172,
    },
    poster: {
      fontWeight: 400,
      fontSize: '1.125rem',
      fontStyle: 'italic',
      lineHeight: 1.5
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          ...(ownerState.variant === 'contained' && {
            fontWeight: 500,
            fontSize: '1.25rem',
            padding: '1rem',
            lineHeight: 0.75
          }),
          ...(ownerState.variant === 'text' && {
            '&:hover': {
              backgroundColor: 'inherit'
            }
          })
        })
      },
      variants: [
        {
          props: { variant: 'register' },
          style: () => ({
            padding: '0.75rem 1rem',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            fontSize: '1.125rem',
            lineHeight: 'normal',
            borderRadius: 4,
            boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.16)',
            '&:hover': {
              backgroundColor: theme.palette.common.white
            }
          })
        }
      ]
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.primary.main,
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '.MuiSvgIcon-root': {
              color: theme.palette.common.white,
              fontSize: 24
            }
          }
        })
      }
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'training' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            padding: '2rem 1.5rem!important',
            '.MuiCardContent-root': {
              paddingTop: '1rem'
            }
          })
        },
        {
          props: { variant: 'volunteer' },
          style: () => ({
            [theme.breakpoints.up('md')]: {
              borderRight: `1px solid ${theme.palette.divider}`
            },
            [theme.breakpoints.down('md')]: {
              borderBottom: `1px solid ${theme.palette.divider}`
            },
            padding: '1.5rem!important',
            background: theme.palette.secondary.light,
            borderRadius: 0,
            '.MuiCardHeader-root': {
              alignItems: 'center',
              paddingBottom: '1rem'
            }
          })
        },
        {
          props: { variant: 'event' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            '.MuiCardHeader-root': {
              padding: '1rem 1rem 0!important'
            },
            '.MuiCardContent-root': {
              padding: '1rem!important'
            }
          })
        },
        {
          props: { variant: 'corp' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            padding: '1rem'
          })
        },
        {
          props: { variant: 'job' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            padding: '1rem 1.5rem'
          })
        },
        {
          props: { variant: 'testimonial' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            '.MuiCardHeader-root': {
              margin: '0 1.5rem',
              padding: '1rem 0',
              borderBottom: `1px solid ${theme.palette.divider}`
            },
            '.MuiCardContent-root': {
              padding: '1rem 1.5rem!important'
            }
          })
        },
        {
          props: { variant: 'blog' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            '.MuiBox-root': {
              lineHeight: 1
            },
            '.MuiCardContent-root': {
              padding: '0.5rem 0!important'
            }
          })
        },
        {
          props: { variant: 'sensitization' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            paddingBottom: '0.5rem',
            borderRadius: 8,
            '.MuiCardHeader-root': {
              padding: '1rem 1rem 0'
            },
            '.MuiCardContent-root': {
              padding: '0 1rem 1.5rem'
            }
          })
        },
        {
          props: { variant: 'learn' },
          style: () => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 8,
            '.MuiCardHeader-root': {
              padding: '1rem 1rem'
            }
          })
        },
        {
          props: { variant: 'team' },
          style: () => ({
            flexDirection: 'row',
            [theme.breakpoints.up('md')]: {
              '.MuiBox-root': {
                padding: '0 1rem'
              }
            },
            '.MuiBox-root': {
              padding: '1rem!important',
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 8,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }
          })
        },
        {
          props: { variant: 'newsletter' },
          style: () => ({
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row'
            },
            '.MuiCardMedia-img': {
              flexGrow: 0,
              [theme.breakpoints.up('md')]: {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              },
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 8
            },
            '.MuiBox-root': {
              backgroundColor: theme.palette.secondary.light,
              padding: '1.5rem!important',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            '.MuiCardActions-root': { padding: '2rem 0 0' }
          })
        },
        {
          props: { variant: 'story' },
          style: () => ({
            backgroundColor: theme.palette.secondary.light,
            padding: 24,
            borderRadius: 8
          })
        },
        {
          props: { variant: 'service' },
          style: () => ({
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              '.MuiCardMedia-img': { width: '30vw' },
              '.MuiBox-root': { padding: '0 1rem' }
            },
            [theme.breakpoints.down('md')]: {
              '.MuiCardHeader-title': { padding: '1.5rem 0 0.5rem!important' },
              '.MuiBox-root': { padding: 0 }
            }
          })
        },
        {
          props: { variant: 'payment' },
          style: () => ({
            borderRadius: 16,
            padding: '1rem',
            '.MuiCardHeader-root': {
              marginBottom: 12
            }
          })
        },
      ],
      styleOverrides: {
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start'
        }
      },
      defaultProps: {
        sx: { padding: 0 }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          '&:last-child': { paddingBottom: 0 }
        }
      },
      defaultProps: {
        sx: { padding: 0 }
      }
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          iframe: { width: '100%' }
        }
      }
    },
    MuiCardActions: {
      defaultProps: {
        sx: { paddingTop: 0, paddingBottom: 2, paddingX: 2 }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '100%',
          width: 'auto',
          ...(ownerState.role === 'tabpanel' && {
            padding: '1.5rem 0!important'
          }),
          ...(ownerState.hidden && {
            display: 'none'
          })
        })
      },
      variants: [
        {
          props: { variant: 'secondary' },
          style: () => ({
            background: theme.palette.secondary.light,
            padding: '2rem 2.5rem'
          })
        }
      ],
      defaultProps: {
        maxWidth: false,
        disableGutters: true,
        sx: {
          paddingY: { xs: 3, sm: 3, md: 5 },
          paddingX: { xs: 3, sm: 5, md: 5 }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor:
            ownerState.component === 'nav'
              ? theme.palette.primary.main
              : theme.palette.common.white
        })
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.common.black,
          fontWeight: 700
        })
      }
    },
    MuiList: {
      variants: [
        {
          props: { variant: 'emphasised' },
          style: {
            fontWeight: 600,
            color: '#4A4A4A',
          }
        }
      ],
      defaultProps: {
        sx: { fontSize: '1rem' }
      }
    },
    MuiListItem: {
      defaultProps: {
        disableGutters: true,
        sx: { padding: 0, paddingTop: 1, lineHeight: 1.315 }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          borderBottom: `1px solid ${theme.palette.divider}`
        })
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: () => ({
            border: 'none',
            '.MuiTabs-indicator': { display: 'none' },
            '.MuiTab-root': {
              color: theme.palette.primary.main,
              fontSize: '20px',
              fontWeight: 500,
              padding: '16px',
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: '4px',
              marginRight: '16px',
              '&.Mui-selected': {
                color: '#ffffff',
                fontWeight: 600
              }
            },
            '.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: '4px',
            }
          })
        }
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          fontSize: '1.125rem',
          textTransform: 'none',
          paddingLeft: '1rem',
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.primary,
        })
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          linkText: 'span'
        }
      }
    }
  }
})

export default theme
