'use client'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Image from 'next/image'
import { styled } from '@mui/material'
import theme from './ThemeRegistry/theme'


const CarouselWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
})

const CarouselSlideWrapper = styled(Box)({
  height: 400,
  position: 'relative'
})

const StyledNavigatorIconButton = styled(IconButton)(() => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
  borderRadius: ' 0 3px 3px 0',
  transition: '0.6s ease',
  position: 'absolute',
  zIndex: 5,
  top: 0,
  bottom: 0,
  fontSize: '2.5rem'
}))

const StyledIndicatorIconButton = styled(IconButton)(({ active }) => ({
  cursor: 'pointer',
  transition: '0.5s',
  color: active ? theme.palette.primary.main : theme.palette.divider,
  '&:active': {
    color: active ? theme.palette.primary.main : theme.palette.divider
  }
}))

const AppCarousel = ({ slideArray }) => {

  const [currentIndex, setCurrentIndex] = useState(0)
  const { url, alternativeText } = slideArray[currentIndex]
  const goToNextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % slideArray.length)
  }

  const goToPrevSlide = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + slideArray.length) % slideArray.length
    )
  }

  return (
    <CarouselWrapper>
      <CarouselSlideWrapper>
        <StyledNavigatorIconButton
          tabIndex={0}
          aria-label='Previous Slide'
          sx={{ left: '3%' }}
          onClick={goToPrevSlide}
          disableRipple
        >
          <ArrowBackIosIcon fontSize='inherit' />
        </StyledNavigatorIconButton>
        <StyledNavigatorIconButton
          tabIndex={0}
          aria-label='Next Slide'
          sx={{ right: '3%' }}
          onClick={goToNextSlide}
          disableRipple
        >
          <ArrowForwardIosIcon fontSize='inherit' />
        </StyledNavigatorIconButton>
        <Box
          title={alternativeText}
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </CarouselSlideWrapper>
      <Indicators active={currentIndex} length={slideArray.length} />
    </CarouselWrapper>
  )
}

export default AppCarousel

export const Indicators = props => {
  const {
    active,
    length,
    indicatorIconButtonProps,
    activeIndicatorIconButtonProps,
    indicatorContainerProps
  } = props

  let indicators = []
  for (let i = 0; i < length; i++) {
    const createIndicator = () => {
      const activeSlide = i === active

      return (
        <StyledIndicatorIconButton
          disableRipple
          active={activeSlide}
          key={i}
          aria-label={`carousel indicator ${i + 1}`}
        >
          {activeSlide ? (
            <Chip color='primary' size='small' sx={{ minWidth: 50 }} />
          ) : (
            <FiberManualRecord />
          )}
        </StyledIndicatorIconButton>
      )
    }

    indicators.push(createIndicator())
  }

  return <Box sx={{ textAlign: 'center' }}>{indicators}</Box>
}
